<template>
  <overlay-base :show="show">
    <v-container class="overlay__body" :fill-height="$vuetify.breakpoint.mdAndUp" restrict-width-wide>
      <v-layout column align-center justify-center>
        <v-progress-circular :size="50" color="primary" indeterminate />
        <div class="save-cc__title mt-12">
          {{ $t('billing.settings.overlay.save_cc.title') }}
        </div>
        <div class="text-center" v-html="$t('billing.settings.overlay.save_cc.text')" />
      </v-layout>
    </v-container>
  </overlay-base>
</template>

<script scoped lang="ts">
import Vue from 'vue'

import OverlayBase from './OverlayBase.vue'

export default Vue.extend({
  name: 'OverlaySaveCreditCard',
  components: {
    OverlayBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped lang="sass">

.save-cc__title
  font-size: 1.5rem
  font-weight: bold
  color: $c-text-alt
  text-align: center
</style>
